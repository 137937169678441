import styled from "styled-components";

export const GreenCircle = styled.img`
  float: right;
  position: absolute;
  display: inline;
  width: 50%;
  right: 0;
  top: 2%;
  @media screen and (max-width: 1200px) {
    width: 50%;
  }

  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 50%;
    top: 3%;
  }
  @media screen and (max-width: 480px) {
    width: 50%;
    top: 5%;
  }
`;

export const BlueCircle = styled.img`
  float: left;
  position: absolute;
  width: 50%;
  top: 50%;
  @media screen and (max-width: 1200px) {
    width: 50%;
  }
  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 798px) {
    width: 50%;
  }
  @media screen and (max-width: 600px) {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    width: 50%;
  }
`;

export const TriangleImage = styled.img`
  float: right;
  position: absolute;
  right: 0;

  top: 66%;
  width: 25%;
  //   @media screen and (max-width: 1200px) {
  //     width: 25%;
  //   }
  //   @media screen and (max-width: 1100px) {
  //     top: 55%;
  //     width: 25%;
  //   }
  //   @media screen and (max-width: 1000px) {
  //     // top: 36em;
  //     width: 25%;
  //   }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
