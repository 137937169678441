import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 9% 3% 4% 3%;
  position: relative;
  z-index: 5;
  @media (max-width: 768px) {
    align-items: center;
    margin: 13% 0 4% 0;
  }
  @media (max-width: 600px) {
    margin: 20% 0 6% 0;
  }
`;
const HeadingStyle = styled.h2`
  padding-bottom: 25px;
  align-self: center;
`;
const CardContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f8ff;
  border-radius: 10px;
  border: 2px solid #3e7bdf;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: q0px;
  max-width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 10px;
    max-width: 78%;
  }
  @media (max-width: 573px) {
    max-width: 90%;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  padding: 10px;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
`;

const ContentWrapper = styled.div`
  flex: 2;
  padding: 10px;
`;

const EventTitle = styled.h2`
  text-align: center;
  margin: 0 0 10px 0;
`;

const EventDescription = styled.p`
  margin: 0 0 20px 0;
`;

const EventDetails = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 10px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;

  svg {
    margin-right: 5px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    align-items: center;
  }

  button {
    margin: 5px;
  }
  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const NoEventText = styled.h2`
  padding: 20px;
  @media (max-width: 415px) {
    padding: 10px;
  }
`;

const handleDate = (date) => {
  const parsedDate = new Date(date);
  return parsedDate.toISOString().split("T")[0];
};
const UpcomingEventCard = ({ cardData }) => {
  return (
    <Wrapper>
      <HeadingStyle>Upcoming Events</HeadingStyle>
      {cardData.results.length > 0 ? (
        cardData.results.map((event, index) => (
          <CardContainer key={index}>
            <ImageWrapper>
              <img src={event.cropped_picture_url} alt="Event" />
            </ImageWrapper>
            <ContentWrapper>
              <EventTitle>{event.title}</EventTitle>
              <EventDescription>{event.description_short}</EventDescription>
              <EventDetails>
                <DetailItem>
                  <span role="img" aria-label="calendar">
                    📅
                  </span>
                  {handleDate(event.start_date)}
                </DetailItem>
              </EventDetails>
              <ActionButtons>
                <Button
                  onClick={() => {
                    window.open(event.url, "_blank");
                  }}
                >
                  More Details
                </Button>
              </ActionButtons>
            </ContentWrapper>
          </CardContainer>
        ))
      ) : (
        <CardContainer style={{ padding: "2em" }}>
          <NoEventText>No upcoming events</NoEventText>
        </CardContainer>
      )}
    </Wrapper>
  );
};

export default UpcomingEventCard;
