import styled from "styled-components";

export const Header = styled.h1`
  border-top: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 50px;
  line-height: 63.6px;
  margin-bottom: 15px;
  margin-top: 120px;
  @media (max-width: 610px) {
    font-size: 45px;
  }
  @media (max-width: 599px) {
    margin-top: 95px;
  }
`;

export const Sponsor = styled.div`
  margin: 0;
  height: 100vh;
  position: relative;
  // overflow: hidden;
  // @media (max-width: 610px) {
  //   overflow: auto;
  // }
  // @media (max-width: 540px) {
  //   overflow: auto;
  //   padding-bottom: 1rem;
  // }
`;
export const HomeCircles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px;
  margin-top: 50px;
  @media (max-width: 540px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    column-gap: 0px;
    justify-content: center;
    justify-items: center;
    margin: 0 auto;
  }
`;

export const TopLeftBg = styled.img`
  position: absolute;
  top: 150px;
  height: 400px;

  z-index: -1;
  @media (max-width: 1169px) {
    height: 350px;
  }
  @media (max-width: 765px) {
    height: 280px;
  }
  @media (max-width: 690px) {
    display: none;
  }
`;

export const BottomLeftBg = styled.img`
  position: absolute;
  bottom: 0px;

  z-index: -1;
  @media (max-width: 1512px) {
    height: 300px;
  }
  @media (max-height: 1366px) {
    display: none;
  }
  @media (max-width: 1169px) {
    height: 300px;
  }
  @media (max-height: 1000px) {
    display: block;
  }
  @media (max-width: 765px) {
    height: 260px;
  }
  @media (max-width: 670px) {
    height: 230px;
  }
  @media (max-width: 540px) {
    display: none;
  }
`;

export const TopRightBg = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  @media (max-width: 1447px) {
    height: 690px;
  }
  @media (max-width: 960px) {
    height: 600px;
    top: 130px;
    right: -10px;
  }
  @media (max-width: 765px) {
    display: none;
  }
`;

export const BottomRightBg = styled.img`
  position: absolute;
  right: -60px;
  bottom: -150px;
  height: 350px;
  z-index: -1;
  bottom: 0;
  @media (max-width: 1447px) {
    height: 40%;
  }
  @media (max-height: 1366px) {
    display: none;
  }
  @media (max-height: 1000px) {
    display: block;
    bottom: -135px;
  }
  @media (max-width: 765px) {
    height: 250px;
  }
  @media (max-width: 620px) {
    display: none;
  }
`;
