import React from "react";
import {
  FaYoutube,
  FaInstagram,
  FaLinkedin,
  FaGithub,
  FaDiscord,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import * as Components from "./footercomponents";
import RedImage from "../../assets/footer/red.png";
import BlueImage from "../../assets/footer/blue.png";
import YellowImage from "../../assets/footer/yellow.png";
import GreenImage from "../../assets/footer/green.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Components.StyledFooter>
        <Components.RedImage src={RedImage} alt="Red GDSC" />
        <Components.BlueImage src={BlueImage} alt="Blue GDSC" />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            order: "1",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              order: "1",
              flex: "row",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Components.LogoAlign style={{ width: "200px" }}>
                <Components.Red>G</Components.Red>
                <Components.Blue>D</Components.Blue>
                <Components.Green>S</Components.Green>
                <Components.Yellow>C</Components.Yellow>
              </Components.LogoAlign>
              <Components.LogoText>SRMIST RAMAPURAM</Components.LogoText>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexFlow: "row",
                order: "2",
                width: "400px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexFlow: "column wrap",
                  width: "140px",
                }}
              >
                <Components.MapHead>SITE MAP</Components.MapHead>
                <Link to="/home" style={{ textDecoration: "none" }}>
                  <Components.MapText>Home</Components.MapText>
                </Link>
                <Link to="/aboutus" style={{ textDecoration: "none" }}>
                  <Components.MapText>About Us</Components.MapText>
                </Link>
                <Link to="/events" style={{ textDecoration: "none" }}>
                  <Components.MapText>Events</Components.MapText>
                </Link>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexFlow: "column wrap",
                }}
              >
                <Components.MapHead>CONNECT</Components.MapHead>
                <Link to="/teammembers" style={{ textDecoration: "none" }}>
                  <Components.MapText>Team</Components.MapText>
                </Link>
                <Link to="/sponsors" style={{ textDecoration: "none" }}>
                  <Components.MapText>Sponsors</Components.MapText>
                </Link>
                {/* <Link to="/blogs" style={{ textDecoration: "none" }}>
                  <Components.MapText>Blogs</Components.MapText>
                </Link> */}
                <Link to="/contactus" style={{ textDecoration: "none" }}>
                  <Components.MapText>Contact Us</Components.MapText>
                </Link>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexFlow: "column wrap",
                order: "3",
                flex: "0 0 220px",
                marginTop: "20px",
              }}
            >
              <Components.Text>
                Join Our Community <br />
                to Get Further Updates
              </Components.Text>
              <Components.StyledButton
                variant="contained"
                color="primary"
                style={{ marginTop: "15px" }}
              >
                <Link
                  to="https://gdsc.community.dev/srm-institute-of-science-and-technology-ramapuram-chennai-india/"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Join Dashboard
                </Link>
              </Components.StyledButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "45px",
              flexFlow: "row wrap",
              order: "4",
              alignItems: "center",
              flex: "0 0 auto",
            }}
          >
            <Components.Socials>
              Socials{" "}
              <i
                class="bi bi-arrow-right-circle"
                style={{
                  fontWeight: 700,
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
              ></i>{" "}
            </Components.Socials>
            <Components.AlginIcons>
              <Link
                to="https://www.instagram.com/gdscsrmrmp/"
                style={{ color: "inherit" }}
              >
                <FaInstagram
                  style={{
                    background:
                      "linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7)",
                    width: "30px",
                    height: "30px",
                    padding: "1px",
                    borderRadius: "5px",
                    margin: "5px",
                    textDecoration: "none",
                  }}
                />
              </Link>
              <Link
                to="https://linkedin.com/in/dsc-srm-ramapuram"
                style={{ color: "inherit" }}
              >
                <FaLinkedin
                  style={{
                    color: "#0A66C2",
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                    margin: "5px",
                  }}
                />
              </Link>
              <Link
                to="https://twitter.com/gdscsrmrmp"
                style={{ color: "inherit" }}
              >
                <FaXTwitter
                  style={{
                    color: "000",
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                    margin: "5px",
                  }}
                />
              </Link>
              <Link
                to="https://discord.com/invite/gyMV2jKJ"
                style={{ color: "inherit" }}
              >
                <FaDiscord
                  style={{
                    background: "#7289DA",
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                    margin: "5px",
                    padding: "1px",
                  }}
                />
              </Link>
              <Link
                to="https://github.com/dsc-srmrmp"
                style={{ color: "inherit" }}
              >
                <FaGithub
                  style={{
                    color: "#181717",
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                    margin: "5px",
                  }}
                />
              </Link>
              <Link
                to="https://www.youtube.com/@gdscsrmrmp"
                style={{ color: "inherit" }}
              >
                <FaYoutube
                  style={{
                    color: "#FF0000",
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                    margin: "5px",
                  }}
                />
              </Link>
            </Components.AlginIcons>
          </div>
        </div>

        <Components.YellowImage src={YellowImage} alt="Yellow GDSC" />
        <Components.GreenImage src={GreenImage} alt="Green GDSC" />
      </Components.StyledFooter>
    </>
  );
};

export default Footer;
