import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutPage from "./aboutus/aboutus";
import EventPage from "./events/eventspage.jsx";
// import Test from "./test.js";
import SponsorsPage from "./sponsors/SponsorsPage.jsx";
import HomePage from "./homepage/HomePage.jsx";
import TeamMembers from "./teams/teampage.jsx";
import Contact from "./contactus/contactUs";
import DownPage from "./downpage.jsx";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DownPage />} />
        <Route path="/qwertyuiop/" element={<HomePage />} />
        <Route path="/qwertyuiop/events" element={<EventPage />} />
        <Route path="/qwertyuiop/aboutus" element={<AboutPage />} />
        <Route path="/qwertyuiop/teammembers" element={<TeamMembers />} />
        <Route path="/qwertyuiop/sponsors" element={<SponsorsPage />} />
        <Route path="/qwertyuiop/home" element={<HomePage />} />
        <Route path="/qwertyuiop/contactus" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
