import React from 'react';
import { Box, Button } from '@mui/material';
import styled from 'styled-components';
import img1 from "./../../assets/aboutus/img1.png";
import img2 from "./../../assets/aboutus/img2.png";
import img3 from "./../../assets/aboutus/img3.png";
import bgImage from "./../../assets/aboutus/bg2.png";
import mImage from "./../../assets/aboutus/MOBILEupdated.png";

const Container = styled.div`
    width: 100%;
    html,
    body {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
`;

const MainBg = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Part = styled.div`
    min-height: 906px;
    width: 100%;
    position: relative;
    padding: 3rem 0;
`;

const Part1 = styled(Part)`
margin-top: 2rem;
   padding: 30px;
   background: #4285F4;
    background-image: url(${bgImage});
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .container {
        
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        
    }

    .container .main-text {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.5rem;

        h1 {
            text-align: center;
        font-size: 6rem;
        font-weight: 700;
       }
       h3 {text-align:center;
        font-size: 1.2rem;
        font-weight: 600;
       }
    }

    .mobile {
        
        width: 100%
    }

    @media (min-width: 1024px) {
     margin-top: 4rem;
        
        .container {
        
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    .container .main-text {
        justify-content: start;
        align-items: start;
        margin-right: 3rem;
        h1 {
            text-align: left;
        font-size: 7rem;
        font-weight: 700;
        margin-bottom: 0px;
       }
       h3 {text-align:left;
        font-size:1.8rem;
        font-weight: 600;
       }
    }
    }

    @media (min-width: 1440px) {
        
        .container {
        
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    .container .main-text {
        justify-content: start;
        align-items: start;
        margin-right: 3rem;
        h1 {
            text-align: left;
        font-size: 10rem;
        font-weight: 700;
        margin-bottom: 0px;
       }
       h3 {text-align:left;
        font-size:2.5rem;
        font-weight: 600;
       }
    }
    }

    @media (min-width: 2560px) {
        
        .container {
        
        width: 100%;
        display: flex;
        flex-direction: row;
        
        justify-content: space-between;
        align-items: center;
        
    }
    .container .main-text {
        
        margin-right: 3rem;
        h1 {
            text-align: left;
        font-size: 12rem;
        font-weight: 700;
        margin-bottom: 0px;
       }
       h3 {text-align:left;
        font-size:2.5rem;
        font-weight: 600;
       }
    }
    } 
`;
const Part2 = styled(Part)`

    padding: 30px;
    
    background: #EA4335;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    width: 100%;

    h1 {
        color: white;
        font-size: 3rem;
        font-weight: 600;
        margin-left:1rem ;
        
    }

    .container {
        
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        
    }

    .container .main-text {
        

        p {
            color: white;
            font-size: 1.5rem;
            font-weight: 400;
           
            
        }
    }

    .img1 {
        
        width: 100%
    }
    @media (min-width: 1024px) {
        h1 {
        font-size: 6rem;
        font-weight: 600;
        margin-left :2rem ;
       }
        .container {
        
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
    }
    .container .main-text {
        
        padding-top: 30px;
        padding-bottom: 30px;
        p {
            font-size: 2rem;
            font-weight: 400;
            
           
            
        }
    }
    }
    @media (min-width: 1440) {
        padding: 30px;
        h1 {
        font-size: 6rem;
        font-weight: 600;
       margin-left: 3rem;
       
     }
        .container {
        
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
     }
      .container .main-text {
        

        p {
            font-size: 3rem;
            font-weight: 400; 
        }
      }
    }
`;
const Part3 = styled(Part)`
   padding: 30px;
    background: #FBBC04;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h1 {
        font-size: 1.5rem;
        font-weight: 600;
       
        margin-bottom: 3rem;
    }

    .container {
        
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .container .main-text {
        

        p {
            font-size: 1.5rem;
            font-weight: 400;
           
            
        }
    }

    .img2 {
        
        width: 100%
    }
    @media (min-width: 1024px) {
        h1 {
        font-size: 3rem;
        font-weight: 600;
       
        margin-bottom: 3rem;
    }
        .container {
        
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
    }
    .container .main-text {
        

        p {
            font-size: 2rem;
            font-weight: 400;
            
           
            
        }
    }
    }



    




    @media (max-width: 1440) {
        padding: 30px;
        h1 {
        font-size: 3rem;
        font-weight: 600;
       
        margin-bottom: 3rem;
    }
        .container {
        
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
    }
    .container .main-text {
        

        p {
            font-size: 2rem;
            font-weight: 400;
            
           
            
        }
    }
    }
`;
const Part4 = styled(Part)`
    padding: 30px;
    background: #0F9D58;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h1 {
        font-size: 1.5rem;
        font-weight: 600;
       
        margin-bottom: 3rem;
    }

    .container {
        
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .container .main-text {
        

        p {
            font-size: 1.5rem;
            font-weight: 400;
           
            
        }
    }

    .img3 {
        
        width: 100%
    }
    @media (min-width: 1024px) {
        h1 {
        font-size: 3rem;
        font-weight: 600;
       
        margin-bottom: 3rem;
    }
        .container {
        
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
    }
    .container .main-text {
        

        p {
            font-size: 2rem;
            font-weight: 400;
            
           
            
        }
    }
    }
    @media (min-width: 1440) {
        padding: 30px;
        h1 {
        font-size: 3rem;
        font-weight: 600;
       
        margin-bottom: 3rem;
    }
        .container {
        
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
    }
    .container .main-text {
        

        p {
            font-size: 3rem;
            font-weight: 500;
            
           
            
        }
    }
    }
`;
const Part5 = styled(Part)`
    background-color: #25272B;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 110%;

    .drop {
        margin-bottom: 100px;
        width:80%;
        
        .dropdown {
            position: relative;
            display: inline-block;
            width:100%;
            
            .dropbtn {
                background-color: #ffffff;
                width:100%;
                height: 70%;
                color: #000000;
                font-size: 2rem;
                font-weight: 50;
                padding: 16px;
                border-radius: 30px;
                border: none;
                cursor: pointer;
                margin-top: -10rem;
            }

            .dropdown-content {
                display: none;
                position: absolute;
                z-index: 1;
                width: 100%;

                a {
                    color: black;
                    padding: 16px;
                    text-decoration: none;
                    font-size: 45px;
                    font-weight: 550;
                    width: 80%;
                    margin-bottom: 1.5rem;
                    display: block;
                    border-radius: 30px;
                    text-align: center;

                    &:nth-child(1) {
                        background-color: #518FFF;
                        margin-top: 1.5rem;
                    }

                    &:nth-child(2) {
                        background-color: #FF3814;
                    }

                    &:nth-child(3) {
                        background-color: #FFCC29;
                    }

                    &:nth-child(4) {
                        background-color: #00AA5E;
                    }

                    &:hover {
                        background-color: #f1f1f1;
                    }
                    
                }
                
            }@media(max-width:1024px) {
                .dropdown-content {
                a {
                    font-size: 1.5rem;
                }
              }
              
            
            @media(max-width:1024px) {
            
            .dropdown-content { position: absolute;
                    z-index: 1;
                    width: 100%;
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    
                }
    
                
        }
           

           
        }
        
        @media(min-width:1024px) {
            .dropdown-content {
                display: none;
                position: absolute;
                z-index: 1;
                width: 100%;}
        &:hover .dropdown-content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                a{
                        font-size: 2rem;
                    }
            }

            &:hover .dropbtn {
                background-color: #f1f1f1;
            }
    }
    
        
    }
   
    
    @media(max-width:1290px) {
        ${Part2}, ${Part3} {
            .main-text {
                align-items: center;
                margin: 2rem 0;
            }

            p {
                text-align: justify;
                font-size: 35px;
                margin: 0 2.77rem;
                line-height: 50px;
            }
        }

        .img1, .img2, .img3 {
            height: 370px;
            width: 370px;
            background-size: cover;
            margin-top: 2rem;
        }

        .main-text h1 {
            font-size: 70px;
        }

        .img2 {
            margin-bottom: 2rem;
        }

        h1 {
            margin-top: 4rem;
            font-size: 50px;
        }

        .dropbtn {
            
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            font-size: 1.5rem;
        }

        .dropdown-content a {
            /* font-size: 30px; */
        }
    }
    

    @media(max-width:735px) {
        .dropbtn {
            
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            font-size: 1.5rem;
            width: 100%;
        }

        .dropdown-content a {
            width:100%;
    
            /* font-size: 30px; */
        }
        p {
            text-align: justify;
            font-size: 30px;
            margin: 0 1.77rem;
            line-height: 45px;
        }

        .img1, .img2, .img3 {
            height: 250px;
            width: 250px;
            background-size: cover;
            margin-top: 2rem;
        }

        .img2 {
            margin-bottom: -10rem;
        }

        .main-text h1 {
            font-size: 50px;
        }

        h1 {
            margin-top: 3rem;
            font-size: 35px;
        }

        ${Part4} {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @media(max-width:665px) {
        .main-text h1 {
            font-size: 70px;
        }

        .main-text h3 {
            font-size: 20px;
        }

        .container .main-text p {
            margin: 0 140px;
        }

        p {
            text-align: justify;
            font-size: 20px;
            margin: 0 1.77rem;
            line-height: 35px;
        }

        .img1, .img2, .img3 {
            height: 150px;
            width: 150px;
            background-size: cover;
            margin-top: 1rem;
        }

        .dropbtn {
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            font-size: 25px;
        }

        .dropdown-content a {
            font-size: 20px;
        }
    }
}
`;

const AboutPage = ({ aboutData }) => {
    return (
        <Container style={{ width: '100% !important' }}>
            <Box  width={200} style={{ width: '100% !important' }} sx={{ width: '100%' }}>
                <div className="Aboutus">
                    <div className="super-bg">
                        <MainBg>
                            <Part1>
                                <div className="container">
                                <div className="main-text">
                                    <h1>GDSC</h1>
                                    <h3>SRM INSTITUTE OF SCIENCE AND <br/>TECHNOLOGY, RAMAPURAM</h3>
                                </div>
                                <div>
                                    <img className='mobile' src={mImage} alt="mobile.png" />
                                </div>
                                </div>
                            </Part1>
                            <Part2>
                               <h1>{aboutData["red-title"]}</h1>
                               <div className="container">
                                <div className="main-text">
                                        
                                        <p>{aboutData["red-desc"]}</p>
                                    </div>
                                <img src={img1} className='img1' alt="img1.png" />
                               </div>
                            </Part2>
                            <Part3>
                                <div className="container">
                                <img src={img2} className='img2' alt="img2.png" />
                                <div className="main-text">
                                    <p>{aboutData["yellow-desc"]}</p>
                                </div>
                                </div>
                            </Part3>
                            <Part4>
                            <h1>{aboutData["green-title"]}</h1>
                                <div className="container">
                                    <div className="main-text">
                                        <p>{aboutData["green-desc"]}</p>
                                    </div>
                                    <img src={img3} className='img3' alt="imp3.png" />
                                </div>
                            </Part4> 
                            <Part5>
                                <Box className="drop" marginBottom={'100px'}>
                                    <div className="dropdown">
                                        <Button className="dropbtn">DOMAINS ▼</Button>
                                        <div className="dropdown-content">
                                            <a href="#">TECHNICAL</a>
                                            <a href="#">DESIGN</a>
                                            <a href="#">CONTENT</a>
                                            <a href="#">MANAGEMENT</a>
                                        </div>
                                    </div>
                                </Box>
                            </Part5>
                        </MainBg>
                    </div>
                </div>
            </Box>
        </Container>
    );
}

export default AboutPage;