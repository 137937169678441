import React from "react";
import { useState, useEffect } from "react";

import "./App.css";
import { db } from "../../backend/firebase";
import { collection, getDocs } from "firebase/firestore";
import logo from "../../assets/teamsimages/logo.png";
import bg1 from "../../assets/teamsimages/bggdsc.png";
import bg2 from "../../assets/teamsimages/bggdsc2.png";
import bg3 from "../../assets/teamsimages/bggdsc3.png";
import bg4 from "../../assets/teamsimages/bggdsc4.png";
import bg5 from "../../assets/teamsimages/bggdsc5.png";
import bg6 from "../../assets/teamsimages/bggdsc6.png";
import bg7 from "../../assets/teamsimages/bggdsc7.png";
import bg8 from "../../assets/teamsimages/bggdsc8.png";
import bg9 from "../../assets/teamsimages/bggdsc9.png";
import bg10 from "../../assets/teamsimages/bggdsc10.png";
import Card from "./Card";

const App = () => {
  const [userData, setUserData] = useState([]);

  var documentMap = new Map();

  const fetchData = async () => {
    try {
      const collectionData = collection(db, "members");
      const membersData = await getDocs(collectionData);

      membersData.docs.forEach((doc) => {
        var documentId = doc.id;
        var documentData = doc.data();
        documentMap.set(documentId, documentData);
      });
      setUserData(documentMap);
    } catch (error) {
      console.error("Error in fetching the data : ", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const memberData = {};

  userData.forEach((value, key) => {
    memberData[key] = value;
  });

  const corearr = [
    memberData["prashanth"],
    memberData["raagavi"],
    memberData["Pranav S"],
    memberData["eeshwar"],
    memberData["arafath"],
  ].filter((member) => member);
  const techarr = [
    memberData["saquib"],
    memberData["ritesh"],
    memberData["nilay"],
    memberData["badri"],
  ].filter((member) => member);
  const desarr = [
    memberData["bhavna"],
    memberData["arun"],
    memberData["kishoreraj"],
  ].filter((member) => member);
  const contarr = [memberData["swapna"]].filter((member) => member);
  const mgmtarr = [memberData["krithik"]].filter((member) => member);
  const mrktarr = [memberData["rakshith"]].filter((member) => member);
  const cardclasses = ["team-card1", "team-card2", "team-card3", "team-card4"];
  return (
    <>
      <div>
        <div className="header-content">
          <div className="team-header">
            <span style={{ color: "#D63F33" }}>T</span>
            <span style={{ color: "#FBBC04" }}>E</span>
            <span style={{ color: "#3E7BDF" }}>A</span>
            <span style={{ color: "#34A853" }}>M</span>
          </div>
          <h1 className="second-header">GDSC SRM IST RMP</h1>
          <div className="circle-content">
            <div className="circle1">
              <img src={logo} alt="logo" className="logo" />
            </div>
            <div className="circle2">
              <p className="learn">LEARN</p>
            </div>
            <div className="circle3">
              <p className="connect">CONNECT</p>
            </div>
            <div className="circle4">
              <p className="grow">GROW</p>
            </div>
          </div>
        </div>
        <div className="page-content">
          <h1 className="core-team">CORE TEAM</h1>
          <p className="core-team-desc">
            Step into the powerhouse of GDSC SRM Ramapuram!
          </p>
          <img src={bg3} alt="bg3" className="bg3" />
          <div
            className={`core-team-container ${
              corearr.length < 4 ? "few-cards" : ""
            }`}
          >
            <>
              {corearr.map((member) => (
                <Card
                  className={cardclasses[Math.floor(Math.random() * 4)]}
                  name={member.name}
                  position={member.position}
                  img={member.image}
                  insta={member.socials?.instagram}
                  link={member.socials?.linkedin}
                  git={member.socials?.github}
                  x={member.socials?.x}
                />
              ))}
            </>
            <img src={bg4} alt="bg4" className="bg4" />
          </div>

          {/* --------------------------- Technical Team -------------------------- */}

          <img src={bg1} alt="bg1" className="bg1" />
          <h1 className="technical-team">TECHNICAL TEAM</h1>
          <p className="technical-team-desc">
            A cohort of tech maestros orchestrating innovations with precision.
          </p>
          <div
            className={`technical-team-container ${
              techarr.length < 4 ? "few-cards" : ""
            }`}
          >
            <>
              {techarr.map((member) => (
                <Card
                  className={cardclasses[Math.floor(Math.random() * 4)]}
                  name={member.name}
                  position={member.position}
                  img={member.image}
                  insta={member.socials?.instagram}
                  link={member.socials?.linkedin}
                  git={member.socials?.github}
                  x={member.socials?.x}
                />
              ))}
            </>
            <img src={bg2} alt="bg2" className="bg2" />
          </div>

          {/* ---------------------Design Team----------------------- */}

          <img src={bg5} alt="bg5" className="bg5" />
          <h1 className="design-team">DESIGN TEAM</h1>
          <p className="design-team-desc">
            A posse of design virtuosos crafting visual magic that captivates
            and inspires
          </p>
          <div
            className={`design-team-container ${
              desarr.length < 4 ? "few-cards" : ""
            }`}
          >
            <>
              {desarr.map((member) => (
                <Card
                  className={cardclasses[Math.floor(Math.random() * 4)]}
                  name={member.name}
                  position={member.position}
                  img={member.image}
                  insta={member.socials?.instagram}
                  link={member.socials?.linkedin}
                  git={member.socials?.github}
                  x={member.socials?.x}
                />
              ))}
            </>
            <img src={bg6} alt="bg6" className="bg6" />
          </div>

          {/* --------------------------------------Content Team--------------------------------- */}

          <h1 className="content-team">CONTENT TEAM</h1>
          <p className="content-team-desc">
            Content mavens spinning tales that stick
          </p>
          <img src={bg7} alt="bg7" className="bg7" />
          <div
            className={`content-team-container ${
              contarr.length < 4 ? "few-cards" : ""
            }`}
          >
            {contarr.map((member) => (
              <Card
                className={cardclasses[Math.floor(Math.random() * 4)]}
                name={member.name}
                position={member.position}
                img={member.image}
                insta={member.socials?.instagram}
                link={member.socials?.linkedin}
                git={member.socials?.github}
                x={member.socials?.x}
              />
            ))}
            <img src={bg8} alt="bg8" className="bg8" />
          </div>

          {/* -------------------- Management Team ------------------------ */}

          <h1 className="management-team">MANAGEMENT TEAM</h1>
          <p className="management-team-desc">
            Strategic minds steering the ship of success with precision and
            vision
          </p>
          <img src={bg9} alt="bg9" className="bg9" />
          <div
            className={`management-team-container ${
              mgmtarr.length < 4 ? "few-cards" : ""
            }`}
          >
            {mgmtarr.map((member) => (
              <Card
                className={cardclasses[Math.floor(Math.random() * 4)]}
                name={member.name}
                position={member.position}
                img={member.image}
                insta={member.socials?.instagram}
                link={member.socials?.linkedin}
                git={member.socials?.github}
                x={member.socials?.x}
              />
            ))}
          </div>
          {/* -------------------- Marketing Team ------------------------ */}

          <h1 className="marketing-team">MARKETING TEAM</h1>
          <p className="marketing-team-desc">
            Orchestrating campaigns that make waves and leave a lasting impact
          </p>
          <img src={bg10} alt="bg10" className="bg10" />
          <div
            className={`marketing-team-container ${
              mrktarr.length < 4 ? "few-cards" : ""
            }`}
          >
            {mrktarr.map((member) => (
              <Card
                className={cardclasses[Math.floor(Math.random() * 4)]}
                name={member.name}
                position={member.position}
                img={member.image}
                insta={member.socials?.instagram}
                link={member.socials?.linkedin}
                git={member.socials?.github}
                x={member.socials?.x}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default App;
