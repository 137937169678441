import React from "react";
import Home from "../../components/home/Home";
import NavBar from "../../components/Navbar/Header";
import Footer from "../../components/footer/footer";

const HomePage = () => {
  return (
    <>
      <NavBar />
      <Home />
      <Footer />
    </>
  );
};

export default HomePage;
