import React from "react";
import NavBar from "../../components/Navbar/Header";
import Sponsors from "../../components/Sponsors/Sponsors";
import Footer from "../../components/footer/footer";

const SponsorsPage = () => {
  return (
    <>
      <NavBar />
      <Sponsors />
      <Footer />
    </>
  );
};

export default SponsorsPage;
