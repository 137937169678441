import React from "react";

// Website Notice
// Thank you for visiting our site.We’re currently performing updates and maintenance, and this site will be temporarily unavailable.

// We appreciate your understanding and patience.

// — The Team



export default function DownPage() {
    return (
        <div className="d-flex align-items-center justify-content-center vh-100 bg-dark">
            <div >
                <h1 className="text-center text-white">
                    Website Notice
                </h1>
                <p className="text-center text-white">
                    Thank you for visiting our site. We’re currently performing maintenance, and this site will be unavailable until further notice.
                </p>
                <p className="text-center text-white">
                    We Deeply appreciate your understanding.
                </p>
                <p className="text-center text-white">
                    -The Team
                </p>
            </div>
        </div>
    );
}
