import React from "react";
import TeamMembersPage from "../../components/team-members/App.jsx";
import Footer from "../../components/footer/footer";
import NavBar from '../../components/Navbar/Header';
import "./teams-member-page.css";

const TeamMembers = () => {
  return (
    <>
      <NavBar />
      <TeamMembersPage />
      <Footer />
    </>
  );
};

export default TeamMembers;
