import { Link } from "react-router-dom";
import React from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

const PAGES = [
  { name: "Home", link: "/home" },
  { name: "About", link: "/aboutus" },
  { name: "Events", link: "/events" },
  { name: "Team", link: "/teammembers" },
  //   // { name: "Blogs", link: "/blogs" },
  { name: "Sponsor", link: "/sponsors" },
  { name: "Contact Us", link: "/contactus" },
];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <React.Fragment>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          {PAGES.map((page, index) => (
            <ListItemButton onClick={() => setOpenDrawer(false)} key={index}>
              <ListItemIcon>
                <Link
                  to={page.link}
                  style={{ textDecoration: "none", color: "GrayText" }}
                >
                  <ListItemText>{page.name}</ListItemText>
                </Link>
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon></MenuIcon>
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
