import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import DrawerComp from "./DrawerComp";
import logo from "./../../assets/Navbar/logo.png";

const PAGES = [
  { name: "Home", path: "/home" },
  { name: "About", path: "/aboutus" },
  { name: "Events", path: "/events" },
  { name: "Team", path: "/teammembers" },
  // { name: "Blogs", path: "/contactus" },
  { name: "Sponsor", path: "/sponsors" },
];

const Header = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const pagePaths = PAGES.map((page) => page.path);
  const activeTab = pagePaths.indexOf(location.pathname);

  return (
    <Box>
      <React.Fragment>
        <AppBar sx={{ background: "white" }}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={logo} alt="GDSC-Logo" height="35" width="80" />
            </Box>
            ;
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              {!isMatch && (
                <Tabs
                  textcolor="7C8DB0"
                  value={activeTab !== -1 ? activeTab : false}
                  indicatorColor="primary"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#3e7bdf",
                      height: 2,
                    },
                  }}
                >
                  {PAGES.map((page, index) => (
                    <Link
                      key={index}
                      to={page.path}
                      style={{ textDecoration: "none", color: "grey" }}
                    >
                      <Tab
                        label={page.name}
                        value={index}
                        sx={{
                          color: index === activeTab ? "black" : "grey",
                        }}
                      />
                    </Link>
                  ))}
                </Tabs>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="div" sx={{ ml: 2 }} />
              {!isMatch && (
                <Link to="/contactus" style={{ textDecoration: "none" }}>
                  <Button variant="contained">Contact Us</Button>
                </Link>
              )}
              {isMatch && <DrawerComp />}
            </Box>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    </Box>
  );
};

export default Header;
