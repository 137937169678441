import React from 'react';
import { Box } from '@mui/material';
import Edits from "../../components/contactuspage/contactus";
import NavBar from "../../components/Navbar/Header";
// import Footer from "../../components/footer/footer";

const Contact = () => {
	return (
		<Box>
			<NavBar />
			<Edits />
		</Box>
	);
};

export default Contact;