import React, { useState, useEffect } from "react";
import * as Components from "./CardComponent";
import { useNavigate } from "react-router-dom";

const SponsorsCard = (props) => {
  const [activeCard, setActiveCard] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCard((prevActiveCard) =>
        prevActiveCard === 0 ? 1 : (prevActiveCard + 1) % props.sponsor.length
      );
    }, 2000);

    return () => clearInterval(interval);
  });

  const getCardStyle = (index) => {
    if (index === 0) {
      return { transform: "translateX(0)" };
    }

    let position = index === activeCard ? 0 : 100;
    return { transform: `translateX(${position}%)` };
  };

  let navigate = useNavigate();
  const handleClick = () => {
    navigate("/contactus");
  };

  return (
    <Components.CardOuter>
      <Components.CardContainer>
        {props.sponsor.map((item, index) => (
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            <Components.SponsorsCard key={index} style={getCardStyle(index)}>
              <Components.SponsorImgDiv>
                <Components.SponsorImg src={item.img} alt={item.alt} />
              </Components.SponsorImgDiv>
            </Components.SponsorsCard>
          </a>
        ))}
      </Components.CardContainer>
      <Components.TextOuter>
        <Components.SponsorText>
          WANT TO BE ONE OF OUR SPONSORS?
        </Components.SponsorText>
        <Components.SponsorUs onClick={handleClick}>
          SPONSOR US
        </Components.SponsorUs>
      </Components.TextOuter>
    </Components.CardOuter>
  );
};
export default SponsorsCard;
