import styled from "styled-components";
import Button from "@mui/material/Button";

export const StyledFooter = styled.footer`
  display: flex;
  flex-flow: column wrap;
  order: -2;
  background: linear-gradient(to right, #ffffff, #c5c5c5);
  color: #fff;
  font-size: 1.5rem;
  position: relative;
  width: 100%;
  height: 60rem;
  // margin-top: 8rem;
  font-family: "Google Sans Display", sans-serif;

  @media screen and (min-width: 900px) {
    flex-flow: row wrap;
    justify-content: center;
    height: 30rem;
  }
`;

export const LogoAlign = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;

  @media screen and (min-width: 900px) {
    margin: 0em 3em;
  }
`;

export const Red = styled.h1`
  color: rgba(234, 67, 53, 1);
  font-weight: 700;
  padding: 0 10px;
  font-size: 80px;
`;

export const Blue = styled.h1`
  color: rgba(62, 123, 223, 1);
  font-weight: 700;
  padding: 0 10px;
  font-size: 80px;
`;

export const Green = styled.h1`
  color: rgba(45, 182, 82, 1);
  font-weight: 700;
  padding: 0 10px;
  font-size: 80px;
`;

export const Yellow = styled.h1`
  color: rgba(249, 171, 0, 1);
  font-weight: 700;
  padding: 0 10px;
  font-size: 80px;
`;

export const LogoText = styled.h1`
  display: flex;
  align-self: center;
  text-align: center;
  color: rgba(105, 99, 99, 10);
  font-weight: 700;
  font-size: 20px;
`;

export const RedImage = styled.img`
  position: absolute;
  width: 18%;
  height: 12%;
  left: 0;
  top: 0;
  order: -1;

  @media screen and (min-width: 900px) {
    height: 30%;
    width: 15%;
  }

  @media screen and (min-width: 1032px) {
    height: 35%;
    width: 12%;
  }
`;

export const BlueImage = styled.img`
  position: absolute;
  width: 20%;
  height: 10%;
  right: 0;
  top: 0;
  order: -1;

  @media screen and (min-width: 900px) {
    height: 18%;
    width: 13%;
  }

  @media screen and (min-width: 1032px) {
    height: 23%;
  }
`;

export const YellowImage = styled.img`
  position: absolute;
  width: 20%;
  height: 12%;
  left: 0;
  bottom: 0;
  order: -1;

  @media screen and (min-width: 900px) {
    height: 20%;
    width: 15%;
  }

  @media screen and (min-width: 1032px) {
    height: 25%;
  }
`;

export const GreenImage = styled.img`
  position: absolute;
  width: 20%;
  height: 12%;
  right: 0;
  bottom: 0;
  order: -1;

  @media screen and (min-width: 900px) {
    height: 25%;
    width: 15%;
  }

  @media screen and (min-width: 1032px) {
    height: 30%;
  }
`;

export const MapHead = styled.div`
  display: flex;
  color: black;
  font-weight: 800;
  font-size: 20px;
  margin: 0.5em 0.5em 0.5em 1em;
  @media screen and (min-width: 900px) {
    // justify-content: center;
  }
`;

export const MapText = styled.div`
  display: flex;
  font-weight: 700;
  color: black;
  font-size: 20px;
  justify-content: start;
  margin: 0em 0.5em 0.5em 1em;
`;

export const Text = styled.div`
  display: flex;
  justify-content: start;
  text-align: center;
  color: black;
  font-weight: 700;
  font-size: 20px;
  align-self: center;
`;

export const StyledButton = styled(Button)`
  display: flex;
  order: 3;
  align-self: center;
`;

export const Socials = styled.div`
  display: flex;
  color: black;
  font-weight: 700;
  font-size: 20px;
  align-self: center;
`;

export const AlginIcons = styled.div`
  display: flex;
  order: 1;
  color: inherit;
`;
