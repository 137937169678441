import React from "react";
import { Box } from "@mui/material";
import EventPageWithEdits from "../../components/eventspage/EventsComponent";
import Footer from "../../components/footer/footer";
import NavBar from "../../components/Navbar/Header";
import Events from "../../components/eventspage/EventsComponent";

const EventPage = () => {
  return (
    <Box>
      <NavBar />
      <Events />
      <Footer />
    </Box>
  );
};

export default EventPage;
