// src/EventsPage.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UpcomingEventCard from "./UpcomingEvent";
import CardGrid from "./PrevEvents";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import * as Components from "./ElementsComponent";
import bluecircle from "../../assets/EventsPage/blue-circle.png";
import greencircle from "../../assets/EventsPage/green-circles.png";
import Triangle from "../../assets/EventsPage/triangle.png";
import eventimg from "../../assets/EventsPage/event.jpg";
import redcircle from "../../assets/EventsPage/red-circles.png";
import eventsimage from "../../assets/EventsPage/event.jpg";

const PageContainer = styled.div`
  padding: 10% 5%;
  z-index: 5;
  background-color: #f7f8fa;
  @media (max-width: 958px) {
    padding: 15% 5%;
  }
  @media (max-width: 545px) {
    padding: 20% 5%;
  }
`;

const Banner = styled.div`
  background: url(${eventimg});
  no-repeat center center;
  background-size: cover;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 10px;
  border: 2px solid #676c72;
  margin: 0 auto;
  max-width: 94%;
`;

const Stats = styled.div`
  color: black;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: -15%;
  width: 80%;
  background-color: #c5c5c5;
  z-index: 2;
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px solid #676c72;
  @media (max-width: 524px) {
    font-size: 0.8em;
    bottom: -12%;
  }
  @media (max-width: 450px) {
    font-size: 0.6em;
    // bottom: -30px;
  }
`;

const Stat = styled.div`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 2em;
  font-weight: bold;
`;

const StatLabel = styled.div`
  font-size: 1em;
  font-weight: bold;
`;

const Events = () => {
  const [count, setCount] = useState(0);
  const [prevEventApiData, setPrevEventApiData] = useState({ results: [] });
  const [newEventApiData, setNewEventApiData] = useState({ results: [] });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://gdsc.community.dev/api/event_slim/?chapter=833&page_size=100&status=Completed&include_cohosted_events=true&visible_on_parent_chapter_only=true&order=-start_date&fields=title,start_date,event_type_title,cropped_picture_url,cropped_banner_url,url,cohost_registration_url,description,description_short"
        );
        const data = await response.json();
        setPrevEventApiData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://gdsc.community.dev/api/event_slim/for_chapter/833/?page_size=100&status=Live&include_cohosted_events=true&visible_on_parent_chapter_only=true&order=start_date&fields=title,start_date,event_type_title,cropped_picture_url,cropped_banner_url,url,cohost_registration_url,description,description_short&page=1"
        );
        const data = await response.json();
        setNewEventApiData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  console.log(newEventApiData.results);
  return (
    <>
      <Components.GreenCircle src={greencircle} />
      <Components.BlueCircle src={bluecircle} />
      <PageContainer>
        <ScrollTrigger onEnter={() => setCount(1)} onExit={() => setCount(0)}>
          <Banner>
            <Stats>
              <Stat>
                <StatNumber>
                  {count && (
                    <CountUp
                      start={0}
                      end={50}
                      duration={2}
                      delay={0}
                      useGrouping={false}
                    />
                  )}{" "}
                  +
                </StatNumber>
                <StatLabel>Events</StatLabel>
              </Stat>
              <Stat>
                <StatNumber>
                  {count && (
                    <CountUp
                      start={0}
                      end={1500}
                      duration={2}
                      delay={0}
                      useGrouping={false}
                    />
                  )}{" "}
                  +
                </StatNumber>
                <StatLabel>Registrations</StatLabel>
              </Stat>
              <Stat>
                <StatNumber>
                  {count && (
                    <CountUp
                      start={0}
                      end={1200}
                      duration={2}
                      delay={0}
                      useGrouping={false}
                    />
                  )}{" "}
                  +
                </StatNumber>
                <StatLabel>Attendees</StatLabel>
              </Stat>
            </Stats>
          </Banner>
        </ScrollTrigger>
        <Components.TriangleImage src={Triangle} alt="TriangleImage" />
        {newEventApiData.results && (
          <UpcomingEventCard cardData={newEventApiData} />
        )}
        {prevEventApiData.results && <CardGrid cardData={prevEventApiData} />}
      </PageContainer>
    </>
  );
};

export default Events;
