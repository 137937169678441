
import AboutPage from '../../components/aboutus/aboutus';
import Footer from '../../components/footer/footer';
import NavBar from "../../components/Navbar/Header";


import { Box } from '@mui/material';
import React, { useEffect, useState } from "react";
import { db } from "../../backend/firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import { ImageOutlined } from '@mui/icons-material';
const Aboutus = () => {
    const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const pages = collection(db, "pages");

      const aboutPage = doc(pages, "about");
      //console.log(pages);
      const aboutDataSnapshot = await getDoc(aboutPage);
      setAboutData(aboutDataSnapshot.data());
    };
    fetchData();
  }, []);

  if (!aboutData) return null;

  return (
    <Box>
        <NavBar />

      <AboutPage aboutData={aboutData} />
        <Footer />
    </Box>

  );};

export default Aboutus;


