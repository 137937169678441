import React from "react";
import googleLogo from "../../assets/home/google-logo.gif";
import TopLeftBgImg from "../../assets/home/top-left-bg.svg";
import MiddleLines from "../../assets/home/middle-lines.svg";
import BottomRightBgImage from "../../assets/home/bottom-right-bg.svg";
import SloganAnimation from "./SloganAnimation";
import * as Components from "./HomeComponent";

const Home = () => {
  return (
    <Components.Home>
      <Components.Google>
        <Components.GoogleImg src={googleLogo} alt="google" />
      </Components.Google>
      <Components.MainHeader> Developer Student Clubs</Components.MainHeader>
      <Components.SubHeader>SRM IST RAMAPURAM</Components.SubHeader>
      <SloganAnimation />
      <Components.TopLeftBg src={TopLeftBgImg} />
      <Components.BottomRightBg src={BottomRightBgImage} />
      <Components.MiddleLines src={MiddleLines} />
    </Components.Home>
  );
};

export default Home;
